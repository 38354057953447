/* eslint-disable */
import React from 'react'
import Helmet from '../components/Helmet'

import { graphql } from 'gatsby'

import marriedCoupleWalkingTrail from '../images/heroes/couple-walking-on-trail-with-bikes.jpg'
import marriedCoupleWalkingTrailMobile from '../images/heroes/couple-walking-on-trail-with-bikes-mobile.jpg'
import coupleUsingLaptop from '../images/people/couple-using-laptop.jpg'
import coupleBrickWall from '../images/people/couple-in-front-of-brick-wall.jpg'

import aetna from '../images/brand-logos/aetna.svg'
import cigna from '../images/brand-logos/cigna.svg'
import regence from '../images/brand-logos/regence.svg'
import united from '../images/brand-logos/united-healthcare.svg'
import wellcare from '../images/brand-logos/wellcare.svg'
import mutual from '../images/brand-logos/mutual-omaha.svg'

import sslSecure from '../images/icons/ssl-secure.svg'
import trustPilot from '../images/icons/trustpilot-stars.svg'

import ResponsiveGrid from '../components/GridLayouts/ResponsiveGrid'
import GlobalBanner from '../components/GlobalBanner'

import {
  Billboard,
  Carousel,
  LinkButton,
  List,
  ListItem,
  Typography,
  VariableContent,
  SplitContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-aboutuslp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const trustSymbols = [
    { src: sslSecure, alt: 'SSL Secure icon' },
    {
      src: trustPilot,
      alt: 'Trustpilot review stars',
      href: 'https://www.trustpilot.com/review/clearlinkinsurance.com',
      target: '_blank',
      className: 'trust-pilot-link',
    },
  ]

  const { requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'About Us | Healthcareplans.com | 833-316-3642',
          description:
            'About healthcareplans.com – compare Medicare Advantage, Part D and Dual Special Needs Plans online or over the phone from the top Insurance providers | 833-316-3642',
          canonical: 'https://healthcareplans.com/about-us',
          robots: 'follow,index',
        },
        path: '/about-us',
        promoCode: '169412',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={marriedCoupleWalkingTrail}
                  alt="elderly couple walking on trail with bicycles"
                  className="elderly-couple-trail-hero"
                />
              }
              mobileImage={
                <img
                  src={marriedCoupleWalkingTrailMobile}
                  alt="elderly couple walking on trail with bicycles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Online Medicare Plan Enrollment via Healthcareplans.com{' '}
                  </Typography>

                  <Typography variant="h4">
                    Fast, easy, and secure enrollment online for Medicare
                    Advantage, DSNP and Part D plans.
                  </Typography>

                  <ResponsiveGrid
                    images={trustSymbols}
                    numberOfColumns={2}
                    numberOfMobileColumns={2}
                    constraint="extraSmall"
                    columnGap="48px"
                    alignItems="center"
                    className="no-margin-left"
                  />
                </>
              }
            />
          </div>
        </div>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={coupleUsingLaptop}
              alt="an elderly couple uses laptop and smiles"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Who we are</Typography>
              <Typography variant="body">
                Working with Clearlink Insurance Agency, Healthcareplans.com is
                designed to connect you with the information you need to make an
                informed Medicare decision.
                <p>
                  An independent, licensed insurance broker,
                  Medicarehealthplans.com partners with leading insurance brands
                  to make it easier to compare and select plans during the
                  Annual Enrollment Period, Open Enrollment Period, and any
                  Special Enrollment Period—without ever having to get on the
                  phone.
                </p>
                <p>
                  To begin comparing plans, click the Get Started button below.
                </p>
              </Typography>
              <LinkButton
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
                color="primary"
                className="margin-x-auto"
                requestId={requestId}
              >
                Get Started
              </LinkButton>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos header bg-lightgray"
          mainContent={
            <Typography variant="h2">Our Insurance Brand Partners </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <GlobalBanner />

        <SplitContent
          className="bg-lightgray"
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={coupleBrickWall}
              alt="an elderly couple poses in front of a brick wall and smiles"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Why entrust your Medicare journey to healthcareplans.com?
              </Typography>
              <List className="text-center-on-small">
                <ListItem>
                  Working with the top insurance providers across the nation, we
                  offer a variety of Medicare Advantage and Medicare Part D
                  plans so you can select the plan that fits your personal needs
                </ListItem>
                <ListItem>
                  Our over 10 years as an insurance broker means you’re getting
                  expert experience
                </ListItem>
                <ListItem>
                  In addition to our online platform, over 300 US-based and
                  US-licensed agents are available for support and service
                </ListItem>
                <ListItem>
                  Become one of the more than 100,000 people we’ve helped
                  navigate the Medicare journey since just 2017
                </ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
          backgroundColor="light"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Robert A. from Texas says
                  </Typography>
                  <Typography variant="body">
                    “...definitely went above and beyond what could be expected
                    on trying to clear up a messy situation with an insurance
                    company. When it looked like all was futile, you were able
                    to come through and provide us with the coverage we were
                    looking for.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">Joseph A. from U.S. says</Typography>
                  <Typography variant="body">
                    “The agent was informative and helpful. A good experience.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
